import PropTypes from "prop-types"
import React from "react"

const OneCol = props => (
  <section className={`section fp-auto-height-responsive ${props.backgroundColour} ${props.alignment} ${props.additionalClasses}`}>
    <div className="container">
      <div className={`one-col`}>
        {props.children}
      </div>
    </div>
  </section>
)

OneCol.propTypes = {
  backgroundColour: PropTypes.string,
  alignment: PropTypes.string
}

OneCol.defaultProps = {
  backgroundColour: `white`,
  alignment: ``,
  additionalClasses: ``,
}

export default OneCol
