import React from "react"
import PropTypes from "prop-types"
import Flickity from 'react-flickity-component'

const flickityOptions = {
  pageDots: true,
  prevNextButtons: false
}

class Carousel extends React.Component {
  render() {
    return (
      <Flickity
        flickityRef={c => this.flkty = c}
        className={`carousel ${this.props.dots}`}
        options={flickityOptions}
        static // default false
        >
        {this.props.children}
      </Flickity>
    )
  }
}

Carousel.propTypes = {
  dots: PropTypes.string,
}

Carousel.defaultProps = {
  dots: ``,
}

export default Carousel
