import PropTypes from "prop-types"
import React from "react"

const TwoCol = props => (
  <section className={`section fp-auto-height-responsive ${props.backgroundColour} ${props.alignment}`}>
    <div className="container">
      <div className={`two-col ${props.columns}`}>
        {props.children}
      </div>
    </div>
  </section>
)

TwoCol.propTypes = {
  backgroundColour: PropTypes.string,
  alignment: PropTypes.string,
  columns: PropTypes.string,
}

TwoCol.defaultProps = {
  backgroundColour: `white`,
  alignment: ``,
  columns: ``,
}

export default TwoCol
