import PropTypes from "prop-types"
import React from "react"
import logo from "../images/Access Logo.svg"

const Footer = () => (
  <footer className="section fp-auto-height">
    <a href="https://www.weareaccess.co.uk/" target="_blank" rel="noreferrer">
      <img src={logo} alt="WeAreAccess logo" className="logo" />
    </a>
  </footer>
)

Footer.propTypes = {
  backgroundColour: PropTypes.string,
}

Footer.defaultProps = {
  backgroundColour: `black`,
}

export default Footer
