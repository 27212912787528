import React from "react"
import PropTypes from "prop-types"

const Form = (props) => {
  const handleSubmit = event => {
    event.preventDefault()

    const fields = Array.prototype.slice
      .call(event.target)
      .filter(el => el.name)
      .reduce(
        (form, el) => ({
          ...form,
          [el.name]: el.value,
        }),
        {}
      )

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode(fields),
    })
      .then((res) => {
        if (!res.ok) {
          props.onError()
        } else {
          props.onSuccess()
        }
      })
      .catch(() => props.onError())
  }

  return (
    <form
      className={`form ${props.dark ? "form--dark" : ""} ${
        props.twoColumns ? "form--two-col" : ""
      }`}
      name={props.formName}
      method="POST"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value={props.formName} />
      {props.children}
    </form>
  )
}

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

Form.propTypes = {
  formName: PropTypes.string.isRequired,
  dark: PropTypes.bool,
  twoColumns: PropTypes.bool,
}

Form.defaultProps = {
  dark: false,
  twoColumns: false,
}

export default Form
