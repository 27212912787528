/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from "react"
import ReactFullpage from "@fullpage/react-fullpage"
import Ticker from "react-ticker"
import Modal from "react-modal"
import Lottie from "react-lottie"

import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Carousel from "../components/carousel"
import TwoCol from "../components/two-col"
import OneCol from "../components/one-col"
import Footer from "../components/footer"
import Form from "../components/form"

//Logo
import logo from "../images/Access Logo.svg"

// Site animations
import macallanMockup from "../images/Macallan Mockup.png"
import arrow from "../images/animated/arrow.json"
import browserLadies from "../images/animated/BrowserLadies.json"
import cog from "../images/animated/cog.json"
import lightbulb from "../images/animated/lightbulb.json"

// Site icons
import websitePerformance from "../images/Website_Performance.svg"
import websiteSecurity from "../images/Website Security.svg"
import accessability from "../images/Accessibilty.svg"
import mobileDevices from "../images/Mobile Devices.svg"
import seoAuthority from "../images/SEO.svg"
import competitorInsight from "../images/Competitor Sector Insight.svg"
import contentProduction from "../images/Content Production.svg"
import mediaMarketing from "../images/Media & Marketing Actvity.svg"
import uxAudience from "../images/UX & Audience Behaviour.svg"
import completeTick from "../images/Complete Tick.svg"

const IndexPage = () => {
  const [showModal, setShowModal] = useState(false)
  const [reportForm, setReportForm] = useState({ success: false, error: false })
  const [contactForm, setContactForm] = useState({ success: false, error: false })

  return (
    <Layout>
      <Helmet title="WeAreAccess - Private Equity" defer={false} />
      <ReactFullpage
        licenseKey = {'27A07AA0-2DB249D9-96538993-3296A3D0'} // Temp API needs purchasing
        css3 = {true}
        fitToSection = {true}
        responsiveWidth = {800}
        navigation = {true}
        render={({ fullpageApi }) => {
          // if (fullpageApi) {
          //   fullpageApi.setAllowScrolling(!showModal)
          // }

          return (
            <div id="fullpage-wrapper">
                <header className="header section section1">
                  <div className="header__inner">
                    <a href="https://www.weareaccess.co.uk/" target="_blank" rel="noreferrer">
                      <img data-aos-duration="600" data-aos-once="true" data-aos-delay="400" data-aos="fade-in" src={logo} alt="WeAreAccess logo" className="logo" />
                    </a>
                    <div data-aos-duration="400" data-aos-anchor-placement="center-center" data-aos-once="true" data-aos="zoom-in-up">
                      <div className="pre-header">Digital Effectiveness for Private Equity</div>
                      <h1>Is maximising digital returns top of your portfolio's agenda?</h1>
                    </div>

                    <div data-aos-duration="600" data-aos-once="true" data-aos-delay="400" data-aos="zoom-in-up">
                      <div className="pull-out">Sharpen up digital performance across your portfolio</div>
                      <button onClick={() => fullpageApi.moveSectionDown()}>
                        <Lottie
                          options={{
                            loop: true,
                            autoplay: true,
                            animationData: arrow,
                            rendererSettings: {
                              preserveAspectRatio: "xMidYMid slice",
                            },
                          }}
                          height={50}
                          width={90}
                        />
                      </button>
                    </div>

                  </div>
                </header>
              <TwoCol backgroundColour={'white'}>
              <div id="first-section">
                <p className="strapline">“62% of Private Equity firms believe that digital transformation helps create sustainable value, but <span className="highlight" data-aos="highlight">only 25% have started</span> digitally transforming”<a href="https://www.pwc.nl/nl/assets/documents/pwc-private-equity-and-digitisation.pdf" target="_blank" rel="noreferrer"><em>PWC.</em></a>
                </p>
                <h2><strong>Have you started yours yet?</strong></h2>
              </div>
              <div>
                <p>For Private Equity digital transformations, the challenge is deciding not only where to spend on digital marketing & innovation, but also how. If you don’t know where your money is best spent to advance your returns, you need to bring in a qualified digital partner.</p>
                <p>A partner on the ground who knows the digital landscape and has the sector experience to maximise your return on investment, who can provide the insight on where improvements can be made, and has the in-house, value-adding capabilities to deliver them. You need someone who makes what’s important measurable, rather than just making what’s measurable important. In short, you need Access.</p>
                <button className="button" onClick={() => fullpageApi.moveSectionDown()}>Get Started</button>
              </div>
            </TwoCol>

            <TwoCol backgroundColour={'grey'}>
              <div>
                <h2>Are your portfolio companies <span className="highlight" data-aos="highlight">maximising</span> all the potential levers for growth?</h2>
                <p>We know digital marketing investment is only a small part of what you have to keep your eyes on, so we don’t envy you. But since it’s one of the quickest and most important paths to deliver increased growth, we’d like to offer you a clear competitive edge.</p>
                <p>We're highly experienced in executing robust techniques to deliver digital growth and profit - and fast. Not only can we share best practice advice on improving conversion rates and user experiences, and creating websites that perform - we will be implementing and delivering the work ourselves.</p>
                <p>Optimising the efficiency of your digital platforms will give your portfolio companies the edge, and even more than that, it’ll make you stand out amongst your peers with investments that deliver.</p>
              </div>
              <div className="image">
                <img src={macallanMockup} alt="Macallan website mockup" />
              </div>
            </TwoCol>

            <TwoCol backgroundColour={'black'} columns={'forty-sixty'}>
              <div>
                <h2>Our proven work</h2>
                <p>We know your market and understand the strategic opportunities. We've helped over half a dozen private equity backed companies generate in excess of double digit growth across many sectors. Take a look at some of our work...</p>
              </div>
              <div>
              <Carousel dots={'dashes'}>
                  <div>
                  <h3>Working closely with a global B2B skin care company, our digital input led to a launch of a new core website, alongside bringing consistency to the branding and marketing activity of the <span>group’s 21 companies across the globe.</span></h3>
                  <ul>
                    <li>Launching multi-national and multi-disciplined microsites leading to one fully integrated global presence across multiple languages and regions</li>
                    <li>Flexible, fast and agile development to deliver sites within only two week lead times when needed</li>
                  </ul>
                  </div>

                  <div>
                  <h3>Working closely with a global B2B skin care company, our digital input led to a launch of a new core website, alongside bringing consistency to the branding and marketing activity of the <span>group’s 21 companies across the globe.</span></h3>
                  <p>Sed porttitor lectus nibh. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Vivamus suscipit tortor eget felis porttitor volutpat. Proin eget tortor risus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. Pellentesque in ipsum id orci porta dapibus. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                  </div>

                  <div>
                  <h3>Working closely with a global B2B skin care company, our digital input led to a launch of a new core website, alongside bringing consistency to the branding and marketing activity of the <span>group’s 21 companies across the globe.</span></h3>
                  <p>Sed porttitor lectus nibh. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                  </div>
              </Carousel>
              </div>
            </TwoCol>

            <TwoCol backgroundColour={'white'} alignment={'left'}>
              <div className="image">
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: cog,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                />
              </div>
              <div>
                <h2>How a strategic <span className="highlight" data-aos="highlight">partnership</span> with Access can maximise your investments</h2>
                <p>Proactive, innovative and agile - we share your passion for growth. After all, that’s why we feel we’d make an effective partner. Our highly skilled team of developers, designers, project managers and digital marketing experts will work together to ultimately deliver extraneous growth, both for your portfolio, your firm, and us.</p>
                <button className="button" onClick={() => fullpageApi.moveTo(8)}>Let's talk</button>
              </div>
            </TwoCol>

            <OneCol backgroundColour={'grey'} additionalClasses={'no-padding'}>
              <div className="one-col__header">
                <h2>Find new ways to make digital <span className="highlight" data-aos="highlight">progress.</span></h2>
                <p>For a taste of what a partnership will offer, our Digital Improvement Report exposes opportunities within your portfolio to showcase where ROI improvements can be made. Produced in-house for a portfolio website of your choosing, request yours today!</p>
                <p>Your analysis could dig into:</p>
              </div>
              <Ticker height={284} speed={8} move={true}>
                {() => (
                  <>
                    <ul className="digital-progress">
                      <li>
                        <img src={websitePerformance} alt="Website Performance" />
                        <span>Website Performance</span>
                      </li>
                      <li>
                        <img src={websiteSecurity} alt="Website Security" />
                        <span>Website Security</span>
                      </li>
                      <li>
                        <img src={accessability} alt="Accessability" />
                        <span>Accessibility</span>
                      </li>
                      <li>
                        <img src={mobileDevices} alt="Mobile & devices" />
                        <span>Mobile & Devices</span>
                      </li>
                      <li>
                        <img src={uxAudience} alt="User Experience" />
                        <span>User Experience</span>
                      </li>
                      <li>
                        <img src={seoAuthority} alt="SEO & Authority" />
                        <span>SEO & Authority</span>
                      </li>
                      <li>
                        <img src={competitorInsight} alt="Social Media" />
                        <span>Social Media</span>
                      </li>
                      <li>
                        <img src={mediaMarketing} alt="ECommerce" />
                        <span>ECommerce</span>
                      </li>
                      <li>
                        <img src={contentProduction} alt="Content Production" />
                        <span>Content production</span>
                      </li>
                    </ul>
                  </>
                )}
              </Ticker>
              <div className="one-col__footer">
                <p>You will receive your report with an overall performance score, alongside some recommendations. We can then work with you to make these improvements a reality, to strengthen your portfolio’s digital landscape.</p>
                <button className="button" onClick={() => setShowModal(true)}>Request your Digital Improvement Report</button>
                <Modal
                  isOpen={showModal}
                  onRequestClose={() => setShowModal(false)}
                  ariaHideApp={false}
                  closeTimeoutMS={500}
                  className="modal request-modal"
                  overlayClassName="modal-overlay"
                >
                  <button className="close" onClick={() => setShowModal(false)} aria-label="Close" />
                  {!reportForm.success ? (  
                    <div>
                      <h2>Request your Digital Improvement Report</h2>
                      <p>Please share with us your contact details as well as the website you wish us to report on, and we’ll be in touch to discuss your needs further.</p>
                      <Form 
                        formName={'report'}
                        dark={true}
                        twoColumns={true}
                        onSuccess={() => setReportForm(prevState => ({...prevState, success: true}))}
                        onError={() => setReportForm(prevState => ({...prevState, error: true}))}
                      >
                        <div>
                          <div className="form-control">
                            <label htmlFor="reportName">Name*</label>
                            <input type="text" name="reportName" id="reportName" required />
                          </div>
                          <div className="form-control">
                            <label htmlFor="title">Job Title</label>
                            <input type="text" name="title" id="title" />
                          </div>
                          <div className="form-control">
                            <label htmlFor="company">Company*</label>
                            <input type="text" name="company" id="company" required />
                          </div>
                          <div className="form-control">
                            <label htmlFor="phone">Phone number</label>
                            <input type="tel" name="phone" id="phone" />
                          </div>
                          <div className="form-control">
                            <label htmlFor="reportEmail">Email address*</label>
                            <input type="email" name="reportEmail" id="reportEmail" required />
                          </div>
                          <div className="form-control">
                            <label htmlFor="website">Report website URL</label>
                            <input type="text" name="website" id="website" />
                          </div>
                        </div>
                        {reportForm.error && <p className="form-error">Something went wrong. Please try again later or contact us on <a href="mailto:digitaltransformation@weareaccess.co.uk" className="form-mail">digitaltransformation@weareaccess.co.uk</a>.</p>}
                        <button className="button" type="submit">Submit</button>
                      </Form>
                    </div>
                  ) : (
                    <div className="success">  
                      <img src={completeTick} alt="Complete Tick" className="success__tick" />
                      <h2>Thank you for requesting your report</h2>
                      <p>Our team will be in touch with you shortly to discuss the next steps. In the
                        meantime, please feel free to contact us on <a href="mailto:digitaltransformation@weareaccess.co.uk" className="form-mail">digitaltransformation@weareaccess.co.uk</a>
                      </p>
                    </div>
                  )}
                </Modal>
              </div>
            </OneCol>

            <TwoCol backgroundColour={'white'}>
              <div>
                <h2>Make the most of your Private Equity portfolio with a <span className="highlight" data-aos="highlight">smarter</span> strategic digital agency partnership</h2>
                <p><em>8 minute read</em></p>
                <a className="button" href="#0">Read the blog</a>
              </div>
              <div className="image">
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: browserLadies,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                />
              </div>
            </TwoCol>

            <TwoCol backgroundColour={'grey'} alignment={'left'}>
              <div className="image">
                <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: lightbulb,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                  />
              </div>
              <div>
                <h2>Get in <span className="highlight" data-aos="highlight">touch.</span></h2>
                <p>Proudly Northern and passionate about supporting our regional economy, engage us as your local expert partner and maximise your digital opportunities.</p>

                <Form 
                  formName={'contact'}
                  onSuccess={() => setContactForm(prevState => ({ ...prevState, success: true }))}
                  onError={() => setContactForm(prevState => ({ ...prevState, error: true }))}
                >
                  <div>
                    <div className="form-control">
                      <label htmlFor="name">Name*</label>
                      <input type="text" name="name" id="name" required disabled={contactForm.success} />
                    </div>
                    <div className="form-control">
                      <label htmlFor="email">Email address*</label>
                      <input type="email" name="email" id="email" required disabled={contactForm.success} />
                    </div>
                    <div className="form-control">
                      <label htmlFor="message">Message*</label>
                      <textarea name="message" id="message" required disabled={contactForm.success}></textarea>
                    </div>
                  </div>
                  {contactForm.error && !contactForm.success && <p className="form-error">Something went wrong. Please try again later or contact us on <a href="mailto:digitaltransformation@weareaccess.co.uk" className="form-mail">digitaltransformation@weareaccess.co.uk</a>.</p>}
                  <button className="button" type="submit" disabled={contactForm.success}>{contactForm.success ? 'Sent' : 'Submit'}</button>
                </Form>
              </div>
            </TwoCol>

            <Footer />
            </div>
          );
      }}
    />
    </Layout>
  )
}




export default IndexPage;
